.text-base-primary {
  color: #0021f8;
}

:root {
  font-size: 16px; /* Set root font size to 16 pixels */
}

* {
  font-size: inherit;
}

/* Remove default clear button (x) in search inputs in WebKit browsers (Chrome, Safari) */
input[type='search']::-webkit-search-cancel-button {
  -webkit-appearance: none;
  appearance: none;
}

/* Remove default clear button (x) in search inputs in IE and Edge */
input[type='search']::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}

/* Remove default clear button (x) in search inputs in Firefox */
input[type='search']::-moz-clear {
  display: none;
}

.tox-statusbar {
  display: none !important;
}
